.wrapper {
  width: 100%;
  background-color: #ededed;
}

.visuallyHidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.innerWrapper {
  width: 100%;
  min-width: 320px;
  max-width: 960px;
  margin: 0 auto;
  list-style-type: none;
  display: block;
}
