.wrapper {
  background-color: #ffffff;
  width: 100%;
  padding-top: 100%;
  position: relative;
  list-style-type: none;
  display: block;
}

.innerWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 58px 6%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .img {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: fadein 1.5s ease;
  animation: fadein 1.5s ease;
} */

.image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  transition: opacity 1.5s ease;
  /* animation: fadein 1.5s ease; */
  /* -webkit-animation: fadein 1.5s ease; */
}

.hiddenImage {
  opacity: 0;
}

.visibleImage {
  opacity: 1;
}

/* @-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

/* @media screen and (max-width: 700px) {
  .innerWrapper {
    padding: 58px 0;
  }
} */
