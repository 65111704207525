@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Open+Sans:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spectral&display=swap);
/*****************************
************************ BASE
*****************************/

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

:root {
  --black: #000000;
  --gray: #767676;
  --light-gray: #767676;
  --white: #fdfdfd;
  --open-sans: "Open Sans", sans-serif;
  --nunito-sans: "Nunito Sans", sans-serif;
  font-size: 16px;
}

html {
  font-family: "Nunito Sans", sans-serif;
  font-family: var(--nunito-sans);
  color: #767676;
  color: var(--gray);
  box-sizing: border-box;
}

/* #root {
  height: 100vh;
  min-width: 380px;
  width: 100%;
} */

.Image_wrapper__16dzU {
  background-color: #ffffff;
  width: 100%;
  padding-top: 100%;
  position: relative;
  list-style-type: none;
  display: block;
}

.Image_innerWrapper__VJput {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 58px 6%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .img {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: fadein 1.5s ease;
  animation: fadein 1.5s ease;
} */

.Image_image__1ln1D {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  transition: opacity 1.5s ease;
  /* animation: fadein 1.5s ease; */
  /* -webkit-animation: fadein 1.5s ease; */
}

.Image_hiddenImage__7iXo6 {
  opacity: 0;
}

.Image_visibleImage__2EFRS {
  opacity: 1;
}

/* @-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

/* @media screen and (max-width: 700px) {
  .innerWrapper {
    padding: 58px 0;
  }
} */

.Home_wrapper__17iG4 {
  width: 100%;
  background-color: #ededed;
}

.Home_visuallyHidden__KZRvN {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.Home_innerWrapper__14KJn {
  width: 100%;
  min-width: 320px;
  max-width: 960px;
  margin: 0 auto;
  list-style-type: none;
  display: block;
}

