@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Open+Sans:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral&display=swap");

/*****************************
************************ BASE
*****************************/

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

:root {
  --black: #000000;
  --gray: #767676;
  --light-gray: #767676;
  --white: #fdfdfd;
  --open-sans: "Open Sans", sans-serif;
  --nunito-sans: "Nunito Sans", sans-serif;
  font-size: 16px;
}

html {
  font-family: var(--nunito-sans);
  color: var(--gray);
  box-sizing: border-box;
}

/* #root {
  height: 100vh;
  min-width: 380px;
  width: 100%;
} */
